<div class="bg-white bgeffect" style="background-image:url(assets/images/skew/services.png); background-repeat:no-repeat; background-size:contain; background-position:left;" data-0="background-position:0px 0px;" data-end="background-position:0px -1000px;">
    <!-- Services List -->
    <div class="section-full content-inner spa-service">
        <div class="container">
            <div class="section-content row">
                <owl-carousel-o [options]="customOptions" class="spa-service-carousel mfp-gallery gallery owl-btn-center-lr owl-btn-spa-lr owl-spa-btn">
                    <ng-template carouselSlide>
                        <div class="spa-service-box">
                            <div class="icon-bx-wraper center">
                                <div class="icon-xl text-primary m-b10"> <a href="#" class="icon-cell text-primary"><i class="flaticon-relax-1"></i></a> </div>
                                <div class="icon-content">
                                    <h3 class="dez-tilte text-uppercase font-weight-600">Быстро</h3>
                                    <p>Наши гости уделяют 20 минут гладкости кожи и забывают об этой заботе на месяц.</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <div class="spa-service-box">
                            <div class="icon-bx-wraper center">
                                <div class="icon-xl text-primary m-b10"> <a href="#" class="icon-cell text-primary"><i class="flaticon-female-hairs"></i></a> </div>
                                <div class="icon-content">
                                    <h3 class="dez-tilte text-uppercase font-weight-600">Качественно</h3>
                                    <p>Квалифицированный опытный мастер и профессиональная продукция - залог нашего успеха.</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <div class="spa-service-box">
                            <div class="icon-bx-wraper center">
                                <div class="icon-xl text-primary m-b10"> <a href="#" class="icon-cell text-primary"><i class="flaticon-massage"></i></a> </div>
                                <div class="icon-content">
                                    <h3 class="dez-tilte text-uppercase font-weight-600">Комфортно</h3>
                                    <p>Комфорт не только после, но и во время процедуры даже самых чувствительных зон.</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <!-- Services List End -->
    <!-- Special Treatments -->
    <div id="master" class="section content-inner spa-service">
        <div class="container">
            <div class="row dzseth">
                <div class="col-md-7 col-sm-6 dis-tbl">
                    <div class="dis-tbl-cell">
                        <h2 class="text-primary spa-title-head ">Мастер Елизавета</h2>
                        <div class="dez-separator spa bg-primary"></div>
                        <ul class="list-icon-box m-t30">
                            <li style="font-size: 28px"><i class="flaticon-nature text-primary"></i><span>Профессионал своего дела</span></li>
                            <li style="font-size: 28px"><i class="flaticon-stones text-primary"></i><span>Опыт в данной сфере более 5-ти лет</span></li>
                            <li style="font-size: 28px"><i class="flaticon-spa text-primary"></i><span>Практикует индивидуальный подход к каждому</span></li>
                            <li style="font-size: 28px"><i class="flaticon-essential-oil text-primary"></i><span>Постоянно совершенствует свои навыки</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6" style="min-height: 550px">
                    <div class="box-img text-right">
                        <img src="assets/images/IMG_9620.jpg" class="cover wow fadeInDown" data-wow-duration="1.50s" data-wow-delay="0.5s" alt="" data-tilt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Special Treatments End -->
</div>
