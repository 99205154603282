import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-her',
  templateUrl: './for-her.component.html',
  styleUrls: ['./for-her.component.css']
})

export class ForHerComponent {
  constructor() { }

}

