<div class="page-wraper">
    <ngx-spinner
        bdColor="rgba(256,256,256,1)"
        color="#fc3d6a"
        type="ball-scale-ripple-multiple">
    </ngx-spinner>
    <app-header></app-header>
    <app-slider></app-slider>
    <!-- <app-offer></app-offer> -->
    <!-- <app-auditory></app-auditory> -->
    <app-advantages></app-advantages>
    <app-stats></app-stats>
    <app-office></app-office>
    <!-- <app-use-cases></app-use-cases> -->
    <!-- <app-contacts></app-contacts> -->
    <!-- <app-example></app-example> -->
    <!-- <app-results></app-results> -->
    <app-reviews></app-reviews>
    <app-prices></app-prices> 
    <app-contacts></app-contacts>
    <app-extrainfo></app-extrainfo>
    <app-questions></app-questions>
    <app-products></app-products>
    <app-contacts></app-contacts>
    <app-footer></app-footer>
    <button class="scroltop fa fa-chevron-up" ></button>
</div>