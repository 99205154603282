<!-- Footer -->
<footer class="site-footer map-footer text-white">
    <div class="footer-top bg-primary">
        <div class="container-fluid">
            <div class="row equal-wraper" >
                <div class="col-md-6 col-sm-6 p-a0 equal-col">
                    <div id="map" style="border:0; min-height:500px; width:100%; height:100%;"></div>
                </div>
                <div class="col-md-6 col-sm-6 equal-col">
                    <div class="p-tb60 p-lr15">
                        <div class="max-w600">
                            <div class="footer-logo m-b30">
                                <img src="assets/images/logo-papillon-white.png" alt="" width="120"/>
                            </div>
                            <div class="m-b0">
                                <h5 class="m-b10 text-white">Мы ждем вас по адресу:</h5>
                                <p>г. Воронеж, ул. Никитинская, д. 42, 5 этаж, оф. 509</p>
                                <p class="m-b25">График работы: с 8:00 до 22:00, без выходных</p>
                                <div class="subscribe-form m-b20">
                                    <form role="search">
                                        <div class="input-group">
                                            <input name="text" class="form-control" placeholder="Email" type="text">
                                            <span class="input-group-btn">
                                                <button type="submit" class="site-button radius-xl">Подпишитесь на рассылку</button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <ul class="list-inline m-a0">
                                    <li><a href="https://www.instagram.com/papillon_vrn" class="site-button instagram footer-instagram circle"><i class="fa fa-instagram"></i></a></li>
                                    <li><button ngxScrollTo [ngxScrollTo]="'#contacts'" [ngxScrollToOffset]="-200" class="site-button instagram footer-instagram circle"><i class="fa fa-whatsapp"></i></button></li>    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="footer-bottom text-left row bg-primary-dark">
                        <div class="col-md-12 col-sm-12 text-left p-lr30">
                            <span>Copyright &copy; 2022 Papillon</span>
                            <div class="widget-link m-t10 ">
                                <ul>
                                    <li><a href="javascript:;">Для нее</a></li>
                                    <li><a href="javascript:;"> Для него</a></li>
                                    <li><a href="javascript:;"> Отзывы</a></li>
                                    <li><a href="javascript:;"> Прайс</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer END-->