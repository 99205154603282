<div class="page-wraper">
    <!-- Content -->
    <div class="blog-page-content style-1 sticky-header">
        <!-- Coming Soon -->
        <div class="dez-coming-soon text-black bg-img-fix dez-coming-soon-full overlay-white-light"
             style="background-image:url(https://butterfly.dexignzone.com/xhtml/images/background/bg14.jpg);">
            <div class="container dez-coming-bx ">
                <div class="top-head text-center logo-header">
                    <a href="index.html">
                        <img src="https://butterfly.dexignzone.com/xhtml/images/logo-white.png" alt="">
                    </a>
                </div>
                <div class="coming-soon-content text-center text-white m-b30">
                    <h2>Страничка в разработке!</h2>
                </div>
                <div class="countdown text-center">
                    <div class="date"><span class="time days text-primary">13</span>
                        <span>Дней</span>
                    </div>
                    <div class="date"><span class="time hours text-primary">07</span>
                        <span>Часов</span>
                    </div>
                    <div class="date"><span class="time mins text-primary">58</span>
                        <span>Минут</span>
                    </div>
                    <div class="date"><span class="time secs text-primary">35</span>
                        <span>Секунд</span>
                    </div>
                </div>
                <div class="text-center m-t50 info-style-1">
                    <a routerLink="/" class="site-button radius-xl m-lr5 openbtn">Свяжитесь с нами чтобы узнать детали!</a>
                </div>
                <div class="social-icon">
                    <ul class="dez-social-icon">
                        <li><a class="fa fa-instagram" href="https://www.instagram.com/papillon_vrn"></a></li>
                        <li><a class="fa fa-vk" href="https://vk.com/id536697495"></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Coming Soon End -->
    </div>
    <!-- Content END-->
</div>