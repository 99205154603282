<div class="main-slider style-two default-banner relative" id="home">
    <div class="tp-banner-container">
        <div class="tp-banner" >
            <div id="rev_slider_1175_1_wrapper" class="rev_slider_wrapper" data-alias="duotone192" data-source="gallery" style="background-color:transparent;padding:0px;">
                <!-- START REVOLUTION SLIDER 5.3.0.2 fullscreen mode -->
                <div id="rev_slider_1175_1" class="rev_slider" style="display:none;" data-version="5.3.0.2">
                    <ul>
                        <!-- SLIDE  -->
                        <li data-index="rs-10" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="default"  data-thumb="assets/images/main-slider/sale-1.jpg"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <!-- MAIN IMAGE -->
                            <img src="assets/images/main-slider/sale-1.jpg" alt=""  data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="110" data-rotatestart="0" data-rotateend="0" data-offsetstart="-500 0" data-offsetend="500 0" data-bgparallax="10" class="rev-slidebg" data-no-retina="">
                            <!-- LAYERS -->
                            <!-- data: [desktop large, notebook, tabler, phone] -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-10-layer-1"
                            
                            data-x="['center','center','center','center']"
                            data-hoffset="['0','0','0','0']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','0']"
                            data-width="full" data-height="full"
                            data-whitespace="nowrap"
                            data-type="shape"
                            data-basealign="slide"
                            data-responsive_offset="off"
                            data-responsive="off"
                            data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                            data-textAlign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style="z-index: 2;background-color:rgba(0, 0, 0, 0.0);border-color:rgba(0, 0, 0, 0);border-width:0px;"> </div>
                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-10-layer-2"
                                data-x="['right','right','right','right']"
                                data-hoffset="['30','85','0','20']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['-160','-120','-160','-150']"
                                data-fontsize="['56','46','42','38']"
                                data-lineheight="['70','70','50','30']"
                                data-width="['720','640','480','300']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5; min-width: 720px; max-width: 720px; white-space: normal; line-height: 70px;  color: rgba(255, 255, 255, 1.00); font-family:'El Messiri',sans-serif;border-width:0px; font-weight:700;">Летняя акция!</div>

                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-10-layer-3"
                                data-x="['right','right','right','right']"
                                data-hoffset="['30','110','0','30']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['-70','-70','-110','-105']"
                                data-fontsize="['28','24','22','18']"
                                data-lineheight="['26','26','26','24']"
                                data-width="['720','820','480','300']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; min-width: 720px; max-width: 720px; white-space: normal;line-height: 26px; font-weight: 400; color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px;">
                                <i>Сезон штанов и водолазок вовсе не повод забыть о комфорте под ними!</i>
                            </div>
                                
                            <!-- LAYER NR. 3 - Ножки ручки -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-10-layer-4"
                                data-x="['right','right','right','right']"
                                data-hoffset="['30','40','0','50']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['30','15','-40','-30']"
                                data-fontsize="['32','32','24','24']"
                                data-lineheight="['26','26','26','24']"
                                data-width="['720','720','480','250']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; min-width: 720px; max-width: 720px; white-space: normal;line-height: 26px; font-weight: 400; color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px;">
                                <i class="fa fa-gift"> </i><i><span style="margin-left: 10px;">Ножки + бикини = ручки в подарок</span></i>
                            </div>

                            <!-- LAYER NR. 4 - Ножки ручки 2-->
                            <div class="tp-caption   rs-parallaxlevel-4"
                            id="slide-10-layer-5"
                            data-x="['right','right','right','right']"
                            data-hoffset="['30','40','0','50']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['60','55','-15','30']"
                            data-fontsize="['32','32','24','24']"
                            data-lineheight="['26','26','26','24']"
                            data-width="['720','720','480','250']"
                            data-height="none"
                            data-whitespace="normal"
                            data-type="text"
                            data-responsive_offset="off"
                            data-responsive="off"
                            data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style="z-index: 6; min-width: 720px; max-width: 720px; white-space: normal;line-height: 26px; font-weight: 400; color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px;">
                                <i class="fa fa-gift"></i><i><span style="margin-left: 10px;">Ножки + ручки = подмышки в подарок</span></i>
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption rs-parallaxlevel-4" 
                            id="slide-10-layer-6" 
                            data-x="['right','right','right','right']" 
                            data-hoffset="['0','210','-50','0']" 
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['200','140','60','120']" 
                            data-height="none"
                            data-whitespace="nowrap"
                            data-width="['720','480','480','300']"
                            data-type="button" 
                            data-responsive_offset="off" 
                            data-responsive="off"
                            data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":7000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style="z-index: 9;">
                            <button ngxScrollTo [ngxScrollToOffset]="-200" [ngxScrollTo]="'#contacts'" onclick="ym(64871119,'reachGoal','schedule_me')" class="site-button text-primary button-xl m-r15 white" type="button">ЗАПИСАТЬСЯ</button></div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption tp-resizeme rs-parallaxlevel-7"
                                id="slide-10-layer-7"
                                data-x="['left','left','left','left']"
                                data-hoffset="['500','500','200','200']"
                                data-y="['top','top','top','top']"
                                data-voffset="['400','400','300','200']"
                                data-width="none"
                                data-height="none"
                                data-whitespace="nowrap"
                                data-type="image"
                                data-responsive_offset="on"
                                data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 8;border-width:0px;">
                                    <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                        <img src="assets/images/main-slider/peralax.png" alt="" data-ww="['590px','479px','383px','287px']" data-hh="['616px','500px','400px','300px']" width="590" height="616" data-no-retina>
                                    </div>
                            </div>
                            <div class="tp-caption   tp-resizeme rs-parallaxlevel-7"
                                id="slide-10-layer-8"
                                data-x="['left','left','left','left']"
                                data-hoffset="['1100','948','948','948']"
                                data-y="['top','top','top','top']"
                                data-voffset="['0','0','0','0']"
                                data-width="none"
                                data-height="none"
                                data-whitespace="nowrap"
                                data-type="image"
                                data-responsive_offset="on"
                                data-frames='[{"from":"opacity:0;","speed":200,"to":"o:5;","delay":200,"ease":"Power2.easeInOut"},{"delay":"wait","speed":2000,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 8;border-width:0px;">
                                    <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                        <img src="assets/images/main-slider/peralax2.png" alt="" data-ww="['376px','376px','376px','376px']" data-hh="['331px','331px','331px','331px']" width="376" height="331" data-no-retina>
                                    </div>
                            </div>
                            <div class="header-set">
                                <img src="assets/images/skew/header-skew.svg" alt=""/>
                            </div>
                            <div class="slider-set">
                                <img src="assets/images/skew/slider-skew.svg" alt=""/>
                            </div>
                        </li>

                                                <!-- SLIDE 2 -->
                        <li data-index="rs-100" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="default"  data-thumb="assets/images/main-slider/slide1.jpg"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <!-- MAIN IMAGE -->
                            <img src="assets/images/main-slider/slide-5_small.jpg" alt=""  data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="110" data-rotatestart="0" data-rotateend="0" data-offsetstart="-500 0" data-offsetend="500 0" data-bgparallax="10" class="rev-slidebg" data-no-retina="">
                            <!-- LAYERS -->
                            <div class="tp-caption tp-shape tp-shapewrapper " 
                                id="slide-100-layer-1"
                                data-x="['center','center','center','center']"
                                data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full"
                                data-whitespace="nowrap"
                                data-type="shape"
                                data-basealign="slide"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 2;background-color:rgba(0, 0, 0, 0.0);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
                            </div>
                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-100-layer-2"
                                data-x="['right','right','right','right']"
                                data-hoffset="['200','100','50','20']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['-170','-120','-160','-130']"
                                data-fontsize="['52','46','46','38']"
                                data-lineheight="['52','70','46','30']"
                                data-width="['520','800','450','320']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5; min-width: 720px; max-width: 720px; white-space: normal; line-height: 70px;  color: rgba(255, 255, 255, 1.00); font-family:'El Messiri',sans-serif;border-width:0px; font-weight:700;">
                                Шёлковая кожа быстро и надолго
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-100-layer-3"
                                data-x="['right','right','right','right']"
                                data-hoffset="['160','230','50','20']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['20','-10','-50','-20']"
                                data-fontsize="['28','24','22','20']"
                                data-lineheight="['26','26','26','20']"
                                data-width="['560','640','480','300']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; min-width: 720px; max-width: 720px; white-space: normal;line-height: 26px; font-weight: 400; color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px;">
                                <i>Теперь ты можешь выделять на депиляцию гораздо меньше времени и быть уверенной в комфорте процедуры</i>
                            </div>
                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption rs-parallaxlevel-4" 
                            id="slide-100-layer-4" 
                            data-x="['right','right','right','right']" 
                            data-hoffset="['0','250','00','-15']" 
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['190','120','70','110']" 
                            data-height="none"
                            data-whitespace="nowrap"
                            data-width="['720','480','480','300']"
                            data-type="button" 
                            data-responsive_offset="off" 
                            data-responsive="off"
                            data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":7000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style="z-index: 9;">
                            <button ngxScrollTo [ngxScrollTo]="'#contacts'" onclick="ym(64871119,'reachGoal','schedule_me')" class="site-button button-xl m-r15 white" type="button">ЗАПИСАТЬСЯ</button></div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption tp-resizeme rs-parallaxlevel-7"
                                id="slide-100-layer-5"
                                data-x="['left','left','left','left']"
                                data-hoffset="['500','500','200','200']"
                                data-y="['top','top','top','top']"
                                data-voffset="['400','400','300','200']"
                                data-width="none"
                                data-height="none"
                                data-whitespace="nowrap"
                                data-type="image"
                                data-responsive_offset="on"
                                data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 8;border-width:0px;">
                                    <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                        <img src="assets/images/main-slider/peralax.png" alt="" data-ww="['590px','479px','383px','287px']" data-hh="['616px','500px','400px','300px']" width="590" height="616" data-no-retina>
                                    </div>
                            </div>
                            <div class="tp-caption   tp-resizeme rs-parallaxlevel-7"
                                id="slide-100-layer-6"
                                data-x="['left','left','left','left']"
                                data-hoffset="['1100','948','948','948']"
                                data-y="['top','top','top','top']"
                                data-voffset="['0','0','0','0']"
                                data-width="none"
                                data-height="none"
                                data-whitespace="nowrap"
                                data-type="image"
                                data-responsive_offset="on"
                                data-frames='[{"from":"opacity:0;","speed":200,"to":"o:5;","delay":200,"ease":"Power2.easeInOut"},{"delay":"wait","speed":2000,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 8;border-width:0px;">
                                    <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                        <img src="assets/images/main-slider/peralax2.png" alt="" data-ww="['376px','376px','376px','376px']" data-hh="['331px','331px','331px','331px']" width="376" height="331" data-no-retina>
                                    </div>
                            </div>
                            <div class="header-set">
                                <img src="assets/images/skew/header-skew.svg" alt=""/>
                            </div>
                            <div class="slider-set">
                                <img src="assets/images/skew/slider-skew.svg" alt=""/>
                            </div>
                        </li>
                        
                        <!-- SLIDE 3 -->
                        <li data-index="rs-200" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="default"  data-thumb="assets/images/main-slider/slide2.jpg"  data-rotate="0"  data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <!-- MAIN IMAGE -->
                            <img src="assets/images/main-slider/slide-3_small.jpg" alt=""  data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="110" data-rotatestart="0" data-rotateend="0" data-offsetstart="-500 0" data-offsetend="500 0" data-bgparallax="10" class="rev-slidebg" data-no-retina="">
                            <!-- LAYERS -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-200-layer-1"
                                data-x="['center','center','center','center']"
                                data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full"
                                data-whitespace="nowrap"
                                data-type="shape"
                                data-basealign="slide"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 2;background-color:rgba(0, 0, 0, 0.0);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-200-layer-2"
                                data-x="['right','right','right','right']"
                                data-hoffset="['200','250','0','-10']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['-170','-120','-160','-120']"
                                data-fontsize="['52','46','46','32']"
                                data-lineheight="['52','70','46','30']"
                                data-width="['620','560','560','360']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5; min-width: 720px; max-width: 720px; white-space: normal; line-height: 70px;  color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px; font-weight:700;">
                                Фраза "Это не для меня" уже не для тебя!
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption   rs-parallaxlevel-4"
                                id="slide-200-layer-3"
                                data-x="['right','right','right','right']"
                                data-hoffset="['70','70','50','0']"
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['-10','-10','-60','-30']"
                                data-fontsize="['28','24','22','24']"
                                data-lineheight="['26','26','26','20']"
                                data-width="['760','740','480','340']"
                                data-height="none"
                                data-whitespace="normal"
                                data-type="text"
                                data-responsive_offset="off"
                                data-responsive="off"
                                data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[50,50,50,50]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; min-width: 720px; max-width: 720px; white-space: normal;line-height: 26px; font-weight: 400; color: rgba(255, 255, 255, 1.00);font-family:'El Messiri',sans-serif;border-width:0px;">
                                <i>Навсегда забудь про бритву и доверься профессионалам</i>
                            </div>
                                <!-- LAYER NR. 3 -->
                                <div class="tp-caption rs-parallaxlevel-4" 
                                id="slide-100-layer-4" 
                                data-x="['right','right','right','right']" 
                                data-hoffset="['0','250','00','-15']" 
                                data-y="['middle','middle','middle','middle']"
                                data-voffset="['190','150','70','110']" 
                                data-height="none"
                                data-whitespace="nowrap"
                                data-width="['720','480','480','300']"
                                data-type="button" 
                                data-responsive_offset="off" 
                                data-responsive="off"
                                data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":7000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                                data-textAlign="['left','left','left','left']"
                                data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]"
                                data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 9;">
                                <button ngxScrollTo [ngxScrollTo]="'#contacts'" onclick="ym(64871119,'reachGoal','schedule_me')" class="site-button button-xl m-r15 white" type="button">ЗАПИСАТЬСЯ</button></div>
                                <!-- LAYER NR. 4 -->
                                <div class="tp-caption   tp-resizeme rs-parallaxlevel-7"
                                    id="slide-200-layer-5"
                                    data-x="['left','left','left','left']"
                                    data-hoffset="['500','948','948','948']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['400','487','487','487']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-type="image"
                                    data-responsive_offset="on"
                                    data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"nothing"}]'
                                    data-textAlign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style="z-index: 8;border-width:0px;">
                                        <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                            <img src="assets/images/main-slider/peralax.png" alt="" data-ww="['590px','590px','590px','590px']" data-hh="['616px','616px','616px','616px']" width="590" height="616" data-no-retina>
                                        </div>
                                </div>
                                <div class="tp-caption   tp-resizeme rs-parallaxlevel-7"
                                    id="slide-200-layer-6"
                                    data-x="['left','left','left','left']"
                                    data-hoffset="['1100','948','948','948']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['0','0','0','0']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-type="image"
                                    data-responsive_offset="on"
                                    data-frames='[{"from":"opacity:0;","speed":200,"to":"o:5;","delay":200,"ease":"Power2.easeInOut"},{"delay":"wait","speed":2000,"to":"opacity:0;","ease":"nothing"}]'
                                    data-textAlign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style="z-index: 8;border-width:0px;">
                                        <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="50px" data-origin="50% 50%">
                                            <img src="assets/images/main-slider/peralax2.png" alt="" data-ww="['376px','376px','376px','376px']" data-hh="['331px','331px','331px','331px']" width="376" height="331" data-no-retina>
                                        </div>
                                </div>
                                <div class="header-set">
                                    <img src="assets/images/skew/header-skew.svg" alt=""/>
                                </div>
                                <div class="slider-set">
                                    <img src="assets/images/skew/slider-skew.svg" alt=""/>
                                </div>
                        </li>
                        <!-- SLIDE  -->
                    </ul>
                    <div class="tp-bannertimer" style="height: 8px; background-color: rgba(255, 255, 255, 0.25);"></div>

                </div>
            </div>
        </div>
    </div>
</div>
