<div class="section-full content-inner overlay-primary-dark main bg-img-fix text-white">
    <div class="container">
        <div class="section-content">
             <div class="row">
                <div class="col-md-12">
                    <div class="sort-title clearfix text-center">
                        <h4>Наши успехи</h4>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 m-b30 text-center">
                    <div class="counter-style-1">
                        <div class="">
                            <i class="icon flaticon-thumb-up text-white"></i> >
                            <span class="counter">160</span>
                        </div>
                        <span class="counter-text">Постоянных клиентов</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 m-b30 text-center">
                    <div class="counter-style-1">
                        <div class="">
                            <i class="icon flaticon-checked text-white"></i> >
                            <span class="counter">1100</span>
                        </div>
                        <span class="counter-text">Гладеньких бикини</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 m-b30 text-center">
                    <div class="counter-style-1">
                        <div class="">
                            <i class="icon flaticon-like text-white"></i> >
                            <span class="counter">420</span>
                        </div>
                        <span class="counter-text">Счастливых девушек и довольных мужчин</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>