<header class="site-header header-transparent spa-header fullwidth">
    <div class="top-bar">
        <div class="container-fluid">
        <div class="row">
            <div class="dez-topbar-right">
            <ul>
                <li><a href="tel:+79202465764" class="site-button-link instagram hover"><i class="fa fa-phone"></i> +7 (920) 246-57-64 </a></li>
                <li><a href="https://telegram.me/Skins_sugaring_vrn" class="site-button-link instagram hover"><i class="fa fa-telegram"></i> Telegram </a></li>
                <li><a href="whatsapp://send?phone=+79202465764" class="site-button-link instagram hover"><i class="fa fa-whatsapp"></i> WhatsUp </a></li>
                <li><a href="https://www.instagram.com/papillon_vrn" class="site-button-link instagram hover"><i class="fa fa-instagram"></i> papillon_vrn</a></li>
                <li><i class="fa fa-envelope-o"></i> depilationvrn@gmail.com </li>
            </ul>
            </div>
        </div>
        </div>
    </div>
    <!-- main header -->
    <div class="sticky-header main-bar-wraper">
        <div class="main-bar clearfix ">
            <div class="container-fluid clearfix">
                <!-- website logo -->
                <div class="logo-header mostion">
                    <a href="/"><img src="assets/images/logo-papillon-transparent.png" alt=""></a>
                </div>
                <!-- nav toggle button -->
                <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed" aria-expanded="false" >
                    <i class="flaticon-menu"></i>
                </button>
                <!-- main nav -->
                <div class="header-nav navbar-collapse collapse">
                    <ul class="nav navbar-nav">
                        <li class="active"> <a routerLink="/">Для нее</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#master'" [ngxScrollToOffset]="-200"> <a>Мастер</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#galerry'" [ngxScrollToOffset]="-200"> <a>Галерея</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#certificates'" [ngxScrollToOffset]="-200"> <a>Сертификаты</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#reviews'" [ngxScrollToOffset]="-200"> <a>Отзывы</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#prices'" [ngxScrollToOffset]="-200"> <a>Цены</a></li>
                        <li ngxScrollTo [ngxScrollTo]="'#contacts'" [ngxScrollToOffset]="-200"> <a>Контакты</a></li>
                        <li popover="Страничка находится в разработке."
                        popoverTitle="Мы активно развиваемся!"
                        placement="bottom">
                        <a routerLink="/" >Для него</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>