<div class="section-full bg-white experience-spa-box">
    <div class="container-fluid">
        <div class="row equal-wraper3" style="padding-bottom: 90px">
            <div class="col-md-12 col-sm-2 col-lg-2  p-l0 equal-col hidden-md hidden-sm hidden-xs"></div>
            <div class="col-md-12 col-lg-10 col-sm-12   p-lr0 equal-col">
                <div class="callout-box experience-spa">
                    <img src="assets/images/skew/moving-bg2.svg" data--200-bottom-top="transform: translateY(50px);" data-top="transform: translateY(-10px);" alt=""/>
                    <div class="callout-content ">
                        <div class="text-white max-w600 m-auto text-center m-b50">
                            <h2 class="spa-title-head text-fly">Наши услуги</h2>
                            <p class="font-18 text-fly">Здесь можно ознакомится со всеми видами и особенностями техник исполнения процедуры депиляции, которые используются в нашем салоне.</p>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-4 col-sm-4 m-b30 wow fadeInUp" data-wow-duration="1.50s" data-wow-delay="0.1s">
                                <div class="icon-bx-wraper center bg-white" style="min-height: 416px" data-tilt="">
                                    <div class="icon-content">
                                        <p>В индустрии красоты постоянно появляются новые разработки, позволяющие избавляться от нежелательных волосков. Полимерная депиляция Skin`s – одно из таких открытий. Это новое слово в индустрии, обладающее многими преимуществами.</p>
                                        <div class="align-bottom">
                                            <h5 class="dez-tilte text-uppercase font-26">Полимер</h5>
                                            <button class="site-button" type="button"><a href="#">УЗНАТЬ БОЛЬШЕ</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-b30 wow fadeInUp" data-wow-duration="1.50s" data-wow-delay="0.5s">
                                <div class="icon-bx-wraper center bg-white" style="min-height: 416px" data-tilt="">
                                    <div class="icon-content">
                                        <p>Ваксинг - это удаление волос при помощи восковой кремообразной массы. Ее наносят на кожу по направлению роста и снимают против роста волос. Не всем подходит данный вид депиляции поскольку он блее пилингующий, нежели полимерная депиляция, которая идеальна для чувствительной кожи.</p>
                                        <div>
                                            <h5 class="dez-tilte text-uppercase font-26">Ваксинг</h5>
                                            <button class="site-button" type="button"><a href="#">УЗНАТЬ БОЛЬШЕ</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-b30 wow fadeInUp" data-wow-duration="1.50s" data-wow-delay="0.9s">
                                <div class="icon-bx-wraper center bg-white" style="min-height: 416px" data-tilt="">
                                    <div class="icon-content">
                                        <p>В наше время очень популярный метод удаления волос сахарной пастой. Придумано множество техник шугаринга для увеличения скорости, результативности и комфорта данной процедуры - мануальная, "итальянская глазурь", шпательная, ракельная, бандажная. Наш мастер поможет вам подобрать ту, которая больше всего подойдет имеено для вас.</p>
                                        <div>
                                            <h5 class="dez-tilte text-uppercase font-26 text-bot">Шугаринг</h5>
                                            <button class="site-button" type="button"><a href="#">УЗНАТЬ БОЛЬШЕ</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>