import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditory',
  templateUrl: './auditory.component.html',
  styleUrls: ['./auditory.component.css']
})
export class AuditoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
