<div class="section-full content-inner bg-white bgeffect" style="background-image:url(assets/images/background/bg10.jpg); background-size:100%;"  data-0="background-position:0px 0px;" data-end="background-position:0px 2000px;">
    <div class="container">
        <div class="row">
            <div class="col-md-12 section-head">
                <h2 class="text-primary spa-title-head ">Популярные вопросы</h2>
                <div class="dez-separator spa bg-primary"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="time-line clearfix">
                    <div class="line-left clearfix">
                        <div class="line-left-box">
                            <div class="line-content-box wow fadeInLeft" data-wow-duration="1.80s" data-wow-delay="0.25s">
                                <h2 class="min-title text-primary m-t0 m-b15">Депиляция или эпиляция? Классификация первая</h2>
                                <p>Депиляция - это временное удаления волос, то есть бритье, кремы, шугаринг, ваксинг, тридинг и т.д. А эпиляция это "освобождение" на длительный или постоянный срок: элос-, фото-, лазер-, электро- эпиляция.</p>
                            </div>
                            <div class="line-num gradient">1</div>
                        </div>
                    </div>
                    <div class="line-right clearfix">
                        <div class="line-right-box">
                            <div class="line-content-box wow fadeInRight" data-wow-duration="1.80s" data-wow-delay="0.50s">
                                <h2 class="min-title text-primary m-t0 m-b15">Расхождение мнений. Классификация вторая</h2>
                                <p>Депиляция - это избавление от растительности с поверхности кожи, а эпиляция - удаление волос с корнем. Соответственно ко второму термину относятся все методы кроме бритья и кремов.</p>
                            </div>
                            <div class="line-num gradient">2</div>
                        </div>
                    </div>
                    <div class="line-left clearfix">
                        <div class="line-left-box">
                            <div class="line-content-box wow fadeInLeft" data-wow-duration="1.80s" data-wow-delay="0.75s">
                                <h2 class="min-title text-primary m-t0 m-b15">Откроем маленький секретик</h2>
                                <p>И депиляция и эпиляция имеют прямой перевод - "удаление волоса" :) Это значит что до сих пор не умолкаемые споры на эту тему лишь от давно возникшего заблуждения.</p>
                            </div>
                            <div class="line-num gradient">3</div>
                        </div>
                    </div>
                    <div class="line-right clearfix">
                        <div class="line-right-box">
                            <div class="line-content-box wow fadeInRight" data-wow-duration="1.80s" data-wow-delay="0.75s">
                                <h2 class="min-title text-primary m-t0 m-b15">Отчего врастают волосы?</h2>
                                <p>Вросший волос обнаруживает себя в момент, когда острый кончик стержня закругляется или растет в сторону, оказываясь внутри кожного покрова. Основная причина врастания - высокая плтность эпридермиса и сухость кожного покрова. Также, что удивительно, одной из набиболее частых причин является регулярное ножение тесного синтетического белья, которое не способствует испарения влаги с поверхности кожи и способствует постоянному трению материала о кожу.</p>
                            </div>
                            <div class="line-num gradient">4</div>
                        </div>
                    </div>
                    <div class="line-left clearfix">
                        <div class="line-left-box">
                            <div class="line-content-box wow fadeInLeft" data-wow-duration="1.80s" data-wow-delay="1s">
                                <h2 class="min-title text-primary m-t0 m-b15">Как избежать вросших волос?</h2>
                                <p>Между процедурами не забывать обрабатывать кожу скрабом раз в три дня, только без фанатизма (не "шлифовать" кожу словно края деревянных деталей конструктора). Баловать кожу питательными кремами, маслами, молочком для тела и тд.</p>
                            </div>
                            <div class="line-num gradient">5</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>