<div class="content-area bgeffect m-t50 p-b60" style="background-image:url(assets/images/background/bg12.jpg);" data-0="background-position:0px 0px;" data-end="background-position:0px 1000px;">
    <!-- Left & right section start -->
    <div class="container">
        <div id="galerry" class="section-head text-center">
            <h2 class="heading-top">Галерея</h2>
            <div class="circle-sap bg-primary"></div>
        </div>
        <!-- Our Awesome Services -->
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="icon-bx-wraper">
                    <div class="icon-md text-black m-b20"> 
                        <a href="#" class="icon-cell text-black"><i class="flaticon-medical"></i></a> 
                    </div>
                    <div class="icon-content m-b30">
                        <h5 class="dez-tilte">Чистота</h5>
                        <p>Каждый день мы тщательно убираем все помещания именно утром рабочего дня.</p>
                    </div>
                </div>
                <div class="icon-bx-wraper m-b30">
                    <div class="icon-md text-black m-b20"> 
                        <a href="#" class="icon-cell text-black"><i class="flaticon-nature"></i></a> 
                    </div>
                    <div class="icon-content">
                        <h5 class="dez-tilte">Правильное разделение зон</h5>
                        <p>Мы максимально комфортно обустроили зоны встречи гостей и проведения процедуры.<br/>Без халтурной шторки :)</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="icon-bx-wraper">
                    <div class="icon-md text-black m-b20"> 
                        <a href="#" class="icon-cell text-black"><i class="flaticon-relax"></i></a> 
                    </div>
                    <div class="icon-content m-b30">
                        <h5 class="dez-tilte">Безопасность</h5>
                        <p>Проводим дезинфекцию всех рабочих поверхностей перед началом каждой процедуры.</p>
                    </div>
                </div>
                <div class="icon-bx-wraper  m-b30">
                    <div class="icon-md text-black m-b20"> 
                        <a href="#" class="icon-cell text-black"><i class="flaticon-sauna"></i></a> 
                    </div>
                    <div class="icon-content">
                        <h5 class="dez-tilte">Зона отдыха</h5>
                        <p>Создали уютное место, где ты сможешь выпить кофе в ожидании такси, либо твоя подружка/муж в ожидании тебя.</p>
                    </div>
                </div>
            </div>
            <div class="office card-container col-md-6 col-sm-6 m-t60 mfp-gallery">
                <div class="dez-box dez-gallery-box">
                    <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow dez-img-effect zoom"> 
                        <a href="javascript:void(0);"><img src="assets/images/office/1_small.jpg" alt=""></a>
                        <div class="overlay-bx">
                            <div class="overlay-icon"> <a href="assets/images/office/1_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Awesome Services End -->
        <!-- Gallery -->
        <div class="m-b10">
            <h5 class="text-uppercase text-right text-primary" style="font-size: 24px; font-weight: 600;">Интерьер</h5>
            <div class="dez-divider bg-primary"><i class="icon-dot c-square"></i></div>
        </div>
        <div class="row">
            <ul id="masonry" class="dez-gallery-listing gallery-grid-4 mfp-gallery">
                <li  class="office card-container col-lg-4 col-md-4 col-sm-6 col-xs-6" >
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow dez-img-effect zoom" > <a href="javascript:void(0);"> <img src="assets/images/office/2_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/2_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="children-aid card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/office/3_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/3_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="commercial card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/office/4_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/4_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="window card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/office/5_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/5_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="apartment card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/office/6_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/6_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="Cargo card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/office/7_small.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/office/7_small.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div id="certificates" class="m-b10">
            <h5 class="text-uppercase text-right text-primary" style="font-size: 24px; font-weight: 600;">Наши сертификаты</h5>
            <div class="dez-divider bg-primary"><i class="icon-dot c-square"></i></div>
        </div>
        <div class="row">
            <ul id="masonry" class="dez-gallery-listing gallery-grid-4 mfp-gallery">
                <li  class="office card-container col-lg-4 col-md-4 col-sm-6 col-xs-6" >
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow dez-img-effect zoom" > <a href="javascript:void(0);"> <img src="assets/images/certificates/1.png"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/1.png" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="children-aid card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/2.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/2.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="commercial card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/3.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/3.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="commercial card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/7.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/7.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="commercial card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/5.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/5.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li  class="commercial card-container col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/6.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/6.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="commercial card-container col-md-offset-4 col-lg-offset-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="dez-box dez-gallery-box">
                        <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow"> <a href="javascript:void(0);"> <img src="assets/images/certificates/4.jpg"  alt=""> </a>
                            <div class="overlay-bx">
                                <div class="overlay-icon"> <a href="assets/images/certificates/4.jpg" class="mfp-link"> <i class="fa fa-search-plus icon-bx-xs"></i> </a> </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- Gallery END -->
    </div>
    <!-- Left & right section  END -->
</div>