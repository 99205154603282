<div id ="reviews" class="section-full overlay-black-middle bg-img-fix content-inner" style="background-image:url(assets/images/background/reviews.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="sort-title text-white clearfix text-center">
                    <h4 style="font-size: 32px">Слова наших клиентов</h4>
                </div>
                <div class="section-content m-b30">
                    <owl-carousel-o [options]="customOptions" class="testimonial-three">
                        
                        <ng-template carouselSlide *ngFor="let person_review of reviews">
                            <div class="testimonial-3 testimonial-bg">
                                <div class="quote-left"></div>
                                <div class="testimonial-text text-with-newlines">
                                    <p>{{person_review.text}}</p>
                                </div>
                                <div class="testimonial-detail"><strong class="testimonial-name">{{person_review.name}}</strong><span class="testimonial-position p-l5">{{person_review.date |  date:'dd/MM/yyyy'}}</span> </div>
                            </div>
                        </ng-template>   
                     </owl-carousel-o>
                </div>
                <div class="sort-title text-white clearfix text-center">
                    <button data-toggle="modal" data-target="#reviewModal" class="site-button radius-xl m-lr5">Оставить отзыв</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bs-example-modal-lg" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="exampleModalLabel">Что вы думаете о нашем салоне?</h4>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name" class="control-label">Ваше имя:</label>
              <input type="text" class="form-control" [(ngModel)]="newReview.name" name="name">
            </div>
            <div class="form-group">
              <label for="text" class="control-label">Текст отзыва:</label>
              <ngx-text-editor [placeholder]="'Введите текст вашего отзыва здесь...'" [spellcheck]="true" [(ngModel)]="newReview.text" name="text"></ngx-text-editor>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Закрыть</button>
          <button type="button" (click)="addReview()" data-dismiss="modal" class="site-button">Отправить</button>
        </div>
      </div>
    </div>
  </div>