<div class="section-full bg-white content-inner">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-head text-center">
                    <h2 class="heading-top" style="color: #fc3d6a">Правила идеальной эпиляции</h2>
                    <div class="circle-sap bg-primary"></div>
                    <h2 class="heading-bottom">Маленькие правила для большого успеха</h2>
                </div>
                <div class="dez-accordion box-sort-in space m-b30" id="accordion07">
                    <div class="panel">
                        <div class="acod-head">
                            <h2 class="acod-title"> <a data-toggle="collapse" href="#collapseOne07" data-parent="#accordion7"><i class="fa fa-female"></i>Иду на эпиляция впервые</a> </h2>
                        </div>
                        <div id="collapseOne07" class="acod-body collapse in">
                            <div class="acod-content">Первое, что беспокоит всех (и даже самых бесстыдных :) ) - это стеснение. Не буду говорить, сколько бикини (и женских и мужских) я сделала, и что еще одно меня не смутит. Я знаю - это ничуть не успокаивает :) 
                                Скажу лишь, что это хорошо! Если ты должна раздеться перед человеком впервые и тебя это не смущает - вот это уже странно! Поэтому просто приди на сеанс чуточку раньше, поговори со мной, мы познакомимся. 
                                Если захочешь, я расскажу все что будет происходить за дверью процедурной. Нет ничего зазорного в депиляции, как и в стыде. Со временем ты успокоишься, и неловкость уйдет покорять ступором других первачков :) 
                            </div>
                        </div>
                    </div>
                    <div class="panel">
                        <div class="acod-head">
                            <h2 class="acod-title"> <a data-toggle="collapse" href="#collapseTwo07" class="collapsed" data-parent="#accordion7"><i class="fa fa-thumbs-up"></i>Подготовка к процедуре</a> </h2>
                        </div>
                        <div id="collapseTwo07" class="acod-body collapse">
                            <div class="acod-content">
                                Небольшой свод правил - это маленький секретик, известный каждому мастеру депиляции, который он обязан раскрыть для каждого своего клиента.
                                <ul class="list-check secondry">
                                    <li>Минимальная длина волосков для полимерного ваксинга от 2-х мм, шугаринга и крем-ваксинга от 4-6 мм.</li>
                                    <li>Отшелушивание кожи не позже чем за 4 дня до сеанса.</li>
                                    <li>Увлажнение только средством на водной основе.</li>
                                    <li>Нельзя загорать 2 дня до процедуры на открытых зонах (руки/ноги/лицо).</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="panel">
                        <div class="acod-head">
                            <h2 class="acod-title"> <a data-toggle="collapse"  href="#collapseThree07" class="collapsed"  data-parent="#accordion7"><i class="fa fa-heart"></i>Забота после/между процедурами</a> </h2>
                        </div>
                        <div id="collapseThree07" class="acod-body collapse">
                            <div class="acod-content">
                                <ul class="list-check secondry">
                                    <li>Не мочить 2 часа после эпиляции.</li>
                                    <li>Воздержаться от посещения бани/сауны в течении суток.</li>
                                    <li>Воздержаться от купания в бассейне/речки в течение суток.</li>
                                    <li>Воздержаться от загара (в том числе искуственного) в течение суток.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>